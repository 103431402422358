import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "../stylesheets/application"
import "./bootstrap_custom.js"

Rails.start()
ActiveStorage.start()

$(function() {
  const setupNotification = function(notificationPollingInterval) {
    const updateNotificationUnreadIcon = function(unread) {
      let imageSrc = unread ? "/images/news-unread-icon.svg" : "/images/news-icon.svg"
      $(".notification-button").attr("src", imageSrc);
    };

    const updateNotificationUnread = function() {
      $.ajax({
        url: "home/notification_unread",
        type: "GET",
        dataType: "json",
      }).done(function(notificationUnread) {
        updateNotificationUnreadIcon(notificationUnread);
      }).fail(function() {
        console.log("Failed to get notification.");
      });
    };

    const updateNotificationDialog = function() {
      const notificationContainerElement = $(".notification-tbody");
      $.ajax({
        url: "home/notifications",
        type: "GET",
        dataType: "json",
      }).done(function(notifications) {
        notificationContainerElement.empty();
        updateNotificationUnreadIcon(false);
        if (notifications && notifications.length > 0) {
          $.each(notifications, function(index, notification) {
            let postTextStr = notification["post_text"].replace(/\r\n/g, "<br>");
            notificationContainerElement.append('\
              <tr>\
                <td class="post-date">\
                  ' + notification["post_date"] + '\
                </td>\
                <td class="post-text">\
                  ' + postTextStr + '\
                </td>\
              </tr>\
            ');
          });
        }
        else {
          let messageText;
          if (isJa()) {
            messageText = "最新のお知らせはありません。";
          }
          else {
            messageText = "No announcements.";
          }
          notificationContainerElement.append(("<tr><td>" + messageText + "</td></tr>"))
        }
      }).fail(function(notifications) {
        console.log("Failed to get notification.");
      });
    };

    $(".notification-button").click(function(){
      updateNotificationDialog();
    });

    setInterval(function(){
      updateNotificationUnread();
    }, notificationPollingInterval);

    updateNotificationUnread();
  };

  const initData = JSON.parse(sharedJson || "{}");
  const enableNotification = initData["enable_notification"];
  const notificationPollingInterval = initData["notification_polling_interval"];
  const locale = initData["locale"];
  let isJa = function() {
    return (locale == 'ja');
  }
  if (enableNotification && notificationPollingInterval) {
    setupNotification(notificationPollingInterval);
  }
});
